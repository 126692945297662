import {
  createHashRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Layout from "./screen/Layout";

function App() {
  const router = createHashRouter(
    createRoutesFromElements(
      <Route>
        <Route element={<Layout />} path="/">
          <Route index element={<h1>Currently Being Built, Apologies for the inconvenience!</h1>} />
        </Route>
      </Route>
    )
  );

  return <RouterProvider router={router} />;
}

export default App;
